import { useMutation } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import PropTypes from 'prop-types';
import React from 'react';
import RiskPredictionsColumns from '../../../constants/risk-predictions-columns';
import { RUN_RISK_DEFAULT_CONTRIBUTION } from '../../../graphql/default/mutation';
import useRiskDefaultResults from '../../../hooks/useRiskDefaultResults';

const predictionTypeToLabel = {
  OFFERED: 'APROBAR',
  REJECTED: 'RECHAZAR',
};
const predictionBooleanToLabel = {
  OFFERED: 'NO',
  REJECTED: 'SI',
};

const RiskDefaultResults = ({ riskEvaluationId }) => {
  const { loading, predictionResult, isResultUnknown } = useRiskDefaultResults(riskEvaluationId);

  const [retrieveContribution, { loading: retrievingContribution },
  ] = useMutation(RUN_RISK_DEFAULT_CONTRIBUTION, {
    variables: {
      riskDefaultResultId: predictionResult?.id,
    },
  });

  if (loading) return <CircularProgress />;

  if (!predictionResult) return <Typography>No se han corrido los modelos predictivos.</Typography>;

  return (
    <>
      {isResultUnknown ? (
        <>
          <Typography variant="h6">
            El modelo{' '}
            <Typography component="span" color="primary">
              NO OBTUVO UNA PREDICCIÓN PRECISA
            </Typography>
            . Se recomienda revisar la evaluación de forma manual.
          </Typography>
          <Typography variant="h6">
            El modelo{' '}
            <Typography component="span" color="primary">
              DE ACEPTACIÓN OBTUVO UN PUNTAJE DE {predictionResult?.modelProbability.toFixed(4)}
            </Typography>
            . Cuando el modelo entrega un valor mayor o igual a{' '}
            <Typography component="span" color="primary">
              {predictionResult?.thresholdUsed.toFixed(4)}
            </Typography>
            , hay una alta probabilidad que la factura{' '}
            <Typography component="span" color="primary">NO{' '}</Typography>
            caiga en default.
          </Typography>
        </>
      ) : (
        <Typography variant="h6">
          El modelo{' '}
          <Typography component="span" color="primary">
            RECOMIENDA {predictionTypeToLabel[predictionResult?.prediction]} {' '}
          </Typography>
          con un puntaje de{' '}
          <Typography component="span" color="primary">
            {predictionResult?.modelProbability.toFixed(4)}
          </Typography>
          . Cuando el modelo entrega un valor mayor o igual a {' '}
          <Typography component="span" color="primary">
            {predictionResult?.thresholdUsed.toFixed(4)}
          </Typography>
          , hay una alta probabilidad que la factura{' '}
          <Typography component="span" color="primary">
            {predictionBooleanToLabel[predictionResult?.prediction]} {' '}
          </Typography>
          caiga en default.
        </Typography>
      )}
      {predictionResult?.variablesContribution?.length === 0 ? (
        <LoadingButton
          variant="contained"
          color="primary"
          loading={retrievingContribution}
          onClick={retrieveContribution}
        >
          Correr contribución
        </LoadingButton>
      ) : (
        <FingoDataGrid
          rows={predictionResult.variablesContribution}
          columns={RiskPredictionsColumns}
          serverFilters={false}
          hideFooter
          sx={{ minHeight: 250 }}
        />
      )}
    </>
  );
};

RiskDefaultResults.propTypes = {
  riskEvaluationId: PropTypes.string,
};

RiskDefaultResults.defaultProps = {
  riskEvaluationId: null,
};

export default RiskDefaultResults;
