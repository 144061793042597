import React, { useCallback, useState, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import PropTypes from 'prop-types';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import { RUN_RATIFICATION_CONTRIBUTION, RATIFICATION_RESULTS } from '@fingo/lib/graphql';
import { LoadingButton } from '@mui/lab';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import moment from 'moment';
import { formatDateTime } from '@fingo/lib/helpers';
import RiskRatificationColumns from '../../../constants/risk-ratification-columns';

const resultParser = {
  WHITHOUT_RATIFICATION_CLIENT: 'SIN RATIFICACIÓN',
  WITH_RATIFICATION: 'CON RATIFICACIÓN',
};

const RatificationResultVariableContribution = ({ riskEvaluationId }) => {
  const [ratificationOutput, setRatificationOutput] = useState(null);
  const [orderBy, setOrderBy] = useState('contribution');
  const [unknownResult, setUnknownResult] = useState(false);
  const { loading } = useQuery(RATIFICATION_RESULTS, {
    variables: { riskEvaluationId },
    onCompleted: ({ ratificationResults }) => {
      if (!ratificationResults.length) return;
      for (let i = 0; i < ratificationResults.length; i += 1) {
        if (ratificationResults[i].ratificationResult) {
          setRatificationOutput(ratificationResults[i]);
          return;
        }
      }
      const WithOutRatification = [
        'WHITHOUT_RATIFICATION_CLIENT',
        'WHITHOUT_RATIFICATION_DEBTOR',
        'WITHOUT_RESTRICTION_CLIENT',
        'WITHOUT_RESTRICTION_DEBTOR',
      ];
      const prediction = ratificationResults.filter(
        (result) => WithOutRatification.includes(
          result.ratificationModel.predictionType.ratificationEnumIfHolds,
        ),
      );
      setUnknownResult(true);
      setRatificationOutput(prediction?.[0] ?? null);
    },
  });
  const [retrieveContribution, { loading: retrievingRatification },
  ] = useMutation(RUN_RATIFICATION_CONTRIBUTION, {
    variables: { ratificationResultId: ratificationOutput?.id },
  });
  // const [
  //   rejectAutomaticRatificationEvaluation,
  //   { loading: rejectingAutomaticRatification },
  // ] = useMutation(REJECT_AUTOMATIC_RATIFICATION_EVALUATION, {
  //   variables: { riskEvaluationModelId: riskEvaluationId },
  // });
  const pinkLabel = useCallback((value) => (
    <Box
      fontWeight="fontWeightMedium"
      display="inline"
      sx={{ color: 'primary.main' }}
    >
      {value}
    </Box>
  ), []);
  const predictionComponent = useMemo(() => {
    if (ratificationOutput?.variablesContribution?.length) return null;
    return (
      <LoadingButton
        variant="contained"
        color="primary"
        loading={retrievingRatification}
        onClick={retrieveContribution}
      >
        Correr contribución
      </LoadingButton>
    );
  }, [retrievingRatification, ratificationOutput, retrieveContribution]);
  const HeaderComponent = () => {
    if (!unknownResult) return null;
    return (
      <Typography variant="h6">
        El modelo{pinkLabel(' NO OBTUVO UNA PREDICCIÓN PRECISA')}. Se recomienda revisar la evaluación de forma manual.
      </Typography>
    );
  };
  const ResultsComponent = () => {
    if (unknownResult) {
      return (
        <Typography variant="h6">
          El modelo {pinkLabel(` SIN RATIFICACIÓN OBTUVO UN PUNTAJE DE ${ratificationOutput?.modelProbability.toFixed(3)}`)}. Cuando el modelo entrega un valor mayor o igual a{pinkLabel(` ${ratificationOutput?.thresholdUsed.toFixed(4)}`)}, hay una alta probabilidad que la factura{pinkLabel(' NO ')}tenga problemas para ratificar
        </Typography>
      );
    }
    return (
      <Typography variant="h6">
        El modelo {pinkLabel(` RECOMIENDA ${resultParser[ratificationOutput?.ratificationResult]} `)} con un puntaje de{pinkLabel(` ${ratificationOutput?.modelProbability.toFixed(3)}`)}. Cuando el modelo entrega un valor mayor o igual a{pinkLabel(` ${ratificationOutput?.thresholdUsed.toFixed(4)}`)}, hay una alta probabilidad que la factura {pinkLabel('NO')} tenga problemas para ratificar
      </Typography>
    );
  };
  if (loading) return <CircularProgress />;
  if (!ratificationOutput) {
    return (
      <Typography>No se han corrido los modelos de ratificación.</Typography>
    );
  }
  const RejectComponent = () => {
    if (unknownResult) return (<></>);
    return (
      <>
        {ratificationOutput.rejectedBy ? (
          <Typography color="text.primary" paragraph gutterBottom>
            Ha sido cancelada su aprobación automática por {' '}
            <Typography component="span" color="primary" fontSize={14} fontWeight={600}>
              {ratificationOutput.rejectedBy.completeName}
            </Typography>
            {' '} a las {formatDateTime(moment(ratificationOutput.rejectedAt))}
          </Typography>
        ) : (
          <Typography color="text.primary" paragraph gutterBottom>
            Se aprobará automáticamente en {' '}
            {ratificationOutput.minutesUntilAutomaticApproval}
            {' '} minutos.
          </Typography>
        )}
        {/* {ratificationOutput.rejectedBy == null && (
        <LoadingButton
          variant="contained"
          color="primary"
          loading={rejectingAutomaticRatification}
          onClick={rejectAutomaticRatificationEvaluation}
        >
          Cancelar aprobación automática
        </LoadingButton>
        )} */}
      </>
    );
  };
  return (
    <>
      <HeaderComponent />
      <ResultsComponent />
      <RejectComponent />
      {predictionComponent}
      {ratificationOutput?.variablesContribution.length ? (
        <Box>
          <FingoDataGrid
            rows={ratificationOutput?.variablesContribution}
            columns={RiskRatificationColumns}
            loadingWithSkeleton={loading}
            serverFilters={false}
            orderBy={orderBy}
            setOrderBy={setOrderBy}
            hideFooter
            sx={{ minHeight: 250 }}
          />
        </Box>
      ) : <></>}
    </>
  );
};

RatificationResultVariableContribution.propTypes = {
  riskEvaluationId: PropTypes.string,
};

RatificationResultVariableContribution.defaultProps = {
  riskEvaluationId: null,
};

export default RatificationResultVariableContribution;
