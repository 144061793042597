import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { useBooleanState } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import AcceptPreOfferEvaluationRequestsDialogProvider from './AcceptPreOfferEvaluationRequestsDialogProvider';
import AcceptOrderingSimulationEvaluationRequestsDialog from './AcceptOrderingSimulationEvaluationRequestsDialog';

const AcceptRiskEvaluationDialog = ({
  companyName,
  setSelectedDocuments,
  filter,
  documentsToEvaluate,
  disabled,
}) => {
  const [openAccept, toggleOpenAccept] = useBooleanState();
  const onClose = () => {
    setSelectedDocuments([]);
    toggleOpenAccept(false);
  };
  const DialogComponent = useMemo(() => {
    if (filter === 'invoice') {
      return (
        <AcceptPreOfferEvaluationRequestsDialogProvider
          onClose={onClose}
          documentsToEvaluate={documentsToEvaluate}
        />
      );
    }
    return (
      <AcceptOrderingSimulationEvaluationRequestsDialog
        onClose={onClose}
        documentsToEvaluate={documentsToEvaluate}
      />
    );
  }, [filter, onClose, openAccept]);
  return (
    <>
      <Button
        color="success"
        size="small"
        variant="contained"
        onClick={toggleOpenAccept}
        disabled={disabled}
      >
        Aceptar
      </Button>
      <FingoDialog
        key="accept-evaluations-dialog"
        id="accept-evaluations-dialog"
        title="Aceptar Evaluaciones de Preoferta"
        subtitle={companyName}
        open={openAccept}
        handleClose={toggleOpenAccept}
        maxWidth="xl"
        fullWidth
      >
        {DialogComponent}
      </FingoDialog>

    </>
  );
};

AcceptRiskEvaluationDialog.propTypes = {
  companyName: PropTypes.string.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default AcceptRiskEvaluationDialog;
