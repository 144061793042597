import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState } from '@fingo/lib/hooks';
import RejectPreOfferEvaluationRequestsDialog from './RejectPreOfferEvaluationRequestsDialog';
import RejectOrderingSimulationEvaluationRequestsDialog from './RejectOrderingSimulationEvaluationRequestsDialog';

const RiskEvaluationReject = ({
  companyName,
  setSelectedDocuments,
  filter,
  documentsToEvaluate,
  disabled,
}) => {
  const [openReject, toggleOpenReject] = useBooleanState();
  const onClose = () => {
    setSelectedDocuments([]);
    toggleOpenReject(false);
  };

  const DialogComponent = useMemo(() => {
    if (filter === 'invoice') {
      return (
        <RejectPreOfferEvaluationRequestsDialog
          onClose={onClose}
          documentsToEvaluate={documentsToEvaluate}
        />
      );
    }
    return (
      <RejectOrderingSimulationEvaluationRequestsDialog
        onClose={onClose}
        documentsToEvaluate={documentsToEvaluate}
      />
    );
  }, [filter, onClose, openReject]);
  return (
    <>
      <Button
        color="error"
        size="small"
        variant="contained"
        onClick={toggleOpenReject}
        disabled={disabled}
      >
        Rechazar
      </Button>
      <FingoDialog
        key="accept-evaluations-dialog"
        id="accept-evaluations-dialog"
        title="Rechazar Evaluaciones de Preoferta"
        subtitle={companyName}
        open={openReject}
        handleClose={toggleOpenReject}
        maxWidth="xl"
        fullWidth
      >
        {DialogComponent}
      </FingoDialog>

    </>
  );
};

RiskEvaluationReject.propTypes = {
  companyName: PropTypes.string.isRequired,
  setSelectedDocuments: PropTypes.func.isRequired,
  filter: PropTypes.string.isRequired,
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  disabled: PropTypes.bool.isRequired,
};

export default RiskEvaluationReject;
