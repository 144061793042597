import { CollapsibleList } from '@fingo/lib/components/lists/CollapsibleList';
import { InvoiceType } from '@fingo/lib/propTypes';
import React from 'react';
import useAlertModelsResults from '../../../../../hooks/useAlertsModelsResults';

const AlertConfigList = ({ invoice }) => {
  const { alertsConfig: items } = useAlertModelsResults(invoice);

  return <CollapsibleList title="Alertas" items={items} startOpen />;
};

AlertConfigList.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default AlertConfigList;
