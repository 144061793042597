import React from 'react';
import PropTypes from 'prop-types';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import { RISK_FACTORING_COLUMNS, DEFAULT_RATIFICATION, DEFAULT_FUNDING_DAYS } from '../../../constants/risk-evaluations';
import AcceptPreOfferEvaluationRequestsDialog from './AcceptPreOfferEvaluationRequestsDialog';

const AcceptPreofferEvaluationRequiestDialogProvider = ({ documentsToEvaluate, onClose }) => {
  const country = useGetCountryFromUrl();
  const columns = RISK_FACTORING_COLUMNS[country?.name] || '';
  const defaultRatification = DEFAULT_RATIFICATION[country?.name] || '';
  const defaultFundingDays = DEFAULT_FUNDING_DAYS[country?.name] || 60;

  return (
    <AcceptPreOfferEvaluationRequestsDialog
      documentsToEvaluate={documentsToEvaluate}
      onClose={onClose}
      factoringColumns={columns}
      defaultRatification={defaultRatification}
      defaultFundingDays={defaultFundingDays}
    />
  );
};

AcceptPreofferEvaluationRequiestDialogProvider.propTypes = {
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
      }),
      companyRestrictions: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string.isRequired,
          status: PropTypes.string.isRequired,
          restriction: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
        }),
      ),
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AcceptPreofferEvaluationRequiestDialogProvider;
