import React, { useState } from 'react';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import UploadDropZoneDialogContainer from '@fingo/lib/components/containers/UploadDropZoneDialogContainer';
import { LoadingButton } from '@mui/lab';
import { UPLOAD_PREEVALUATIONS } from '@fingo/lib/graphql';
import { useMutation } from '@apollo/client';

const LoadPreEvaluationsDialog = () => {
  const { addAlert } = useSnackBars();
  const [open, setOpen] = useBooleanState();
  const [files, setFiles] = useState([{ name: '' }]);
  const [error, setError] = useState(null);
  const [uploadPreEvaluations, { loading },
  ] = useMutation(UPLOAD_PREEVALUATIONS, {
    variables: {
      file: files[0],
    },
    onCompleted: () => {
      setOpen();
      addAlert({
        id: 'pre-evaluations-success',
        severity: 'success',
        color: 'success',
        message: 'Facturas mandadas a preevaluar con exito',
      });
    },
    onError: (err) => addAlert({
      id: 'pre-evaluations-error',
      severity: 'error',
      color: 'error',
      message: `Error al cargar preevaluaciones: ${err}`,
    }),
  });
  return (
    <>
      <Button
        color="primary"
        size="small"
        variant="contained"
        onClick={setOpen}
      >
        Cargar Pre Evaluaciones
      </Button>
      <FingoDialog
        key="pre-evaluation-dialog"
        id="pre-evaluation-dialog"
        title="Preevaluar Facturas"
        open={open}
        handleClose={() => {
          setFiles([{ name: '' }]);
          setOpen();
        }}
        maxWidth="sm"
        fullWidth
        dialogActionButton={(
          <LoadingButton
            size="small"
            color="primary"
            variant="contained"
            onClick={uploadPreEvaluations}
            loading={loading}
            disabled={files[0].name === ''}
          >
            Cargar facturas
          </LoadingButton>
        )}
      >
        <Stack spacing={2} alignItems="center" alignContent="center">
          <Typography variant="h2">
            Selecciona el archivo .xlsx con la columna:
          </Typography>
          <Stack direction="row" spacing={6}>
            <Box sx={{ fontFamily: 'Monospace' }}>invoice_id</Box>
          </Stack>
          <Stack sx={{ width: '70%' }}>
            <UploadDropZoneDialogContainer
              files={files}
              setFiles={setFiles}
              errorMutation={error}
              setErrorMutation={setError}
            />
          </Stack>
        </Stack>
      </FingoDialog>
    </>
  );
};

export default LoadPreEvaluationsDialog;
