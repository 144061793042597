import PropTypes from 'prop-types';
import React from 'react';
import BaseRiskResult from './BaseRiskResult';

const RasRiskResults = ({ riskEvaluation, documentId, documentType }) => {
  const selectedModel = 'RAS';
  return (
    <BaseRiskResult
      riskEvaluation={riskEvaluation}
      documentId={documentId}
      documentType={documentType}
      selectedModel={selectedModel}
    />
  );
};

RasRiskResults.propTypes = {
  riskEvaluation: PropTypes.shape({
    treeClientDebtorResult: PropTypes.bool.isRequired,
    treeClientResult: PropTypes.bool.isRequired,
    treeDebtorResult: PropTypes.bool.isRequired,
    rasClientDebtorResult: PropTypes.number.isRequired,
    rasClientResult: PropTypes.number.isRequired,
    rasDebtorResult: PropTypes.number.isRequired,
  }),
  documentId: PropTypes.string.isRequired,
  documentType: PropTypes.string.isRequired,
};

RasRiskResults.defaultProps = {
  riskEvaluation: null,
};

export default RasRiskResults;
