import React from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import { useBooleanState } from '@fingo/lib/hooks';
import Box from '@mui/system/Box';
import Stack from '@mui/material/Stack';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import { LoadingIconButton } from '@fingo/lib/components/buttons';
import { GET_MASTER_ENTITY_METABASE_URL } from '../../../graphql/default/GetMasterEntityMetabaseURL';

const RiskEvaluationHistoricMetricsDialog = ({ masterEntityId }) => {
  const [openDialog, setOpenDialog] = useBooleanState();
  const [fetchMetabaseURL, { data, loading }] = useLazyQuery(
    GET_MASTER_ENTITY_METABASE_URL,
    {
      variables: { masterEntityId },
      onCompleted: () => setOpenDialog(true),
    },
  );
  return (
    <Stack direction="column" alignItems="center">
      <LoadingIconButton
        loading={loading}
        onClick={fetchMetabaseURL}
      >
        <AnalyticsIcon
          color="primary"
          size="small"
        />
      </LoadingIconButton>
      <FingoDialog
        title="Analítica Empresa"
        open={openDialog}
        handleClose={setOpenDialog}
        maxWidth="xl"
        fullWidth
      >
        <Box sx={{ display: 'flex', height: '70vh' }}>
          <iframe
            src={data?.getMasterEntity?.metabaseRiskUrl}
            width="100%"
            height="100%"
            title="MiIframe"
          />
        </Box>
      </FingoDialog>
    </Stack>
  );
};

RiskEvaluationHistoricMetricsDialog.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
};

export default RiskEvaluationHistoricMetricsDialog;
