import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation } from '@apollo/client';
import useOrderingLucilaPreColumns from '@fingo/lib/constants/ordering-lucila-pre-columns';
import { REJECT_ORDERING_SIMULATION, GET_COMPANY_ORDERING_EVALUATIONS } from '@fingo/lib/graphql';
import { GET_PREOFFERS_REQUESTS_ORDERING } from '../../../graphql/default/GetPreoffersRequestsOrderingQuery';
import riskEvaluationAcceptRejectColumns from '../../../constants/risk-evaluations-accept-reject-columns';
import RejectEvaluationRequestsForm from '../forms/RejectEvaluationRequestsForm';

const ORDERING_COLUMNS = [
  'orderNumber',
  'purchaser_Name',
  'orderingsimulation_InvoiceIssuedDate',
  'orderingsimulation_InvoiceDateToPay',
  'rejectionReason',
  'totalAmount',
  'receiverBlacklist',
];

const RejectOrderingSimulationEvaluationRequestsDialog = ({ onClose, documentsToEvaluate }) => {
  const [selectedPreOfferEvaluation, setSelectedPreOfferEvaluation] = useState(''); // ! Cambiar nombre
  const [openOtherRejectionReason, setOpenOtherRejectionReason] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);
  const [analystDescription, setAnalystDescription] = useState('');
  const [orderingSimulationEvaluationRequests, setOrderingSimulationEvaluationRequests] = useState(
    documentsToEvaluate.map((document) => ({
      ...document,
      rejectionReason: '',
      otherRejectionReason: '',
    })),
  );
  const [rejectOrderingSimulation, { loading, error }] = useMutation(REJECT_ORDERING_SIMULATION, {
    variables: {
      requestResolutions: orderingSimulationEvaluationRequests.map((document) => ({
        requestId: document.orderingsimulation.id,
        rejectionReason: document.rejectionReason,
        otherRejectionReason: document.otherRejectionReason,
      })),
      analystDescription,
      sendEmail,
    },
    refetchQueries: [GET_PREOFFERS_REQUESTS_ORDERING, GET_COMPANY_ORDERING_EVALUATIONS],
    onCompleted: () => {
      onClose();
    },
  });

  const updatePreoffer = (id, event) => {
    const { name, value } = event.target;
    const newData = [...orderingSimulationEvaluationRequests];
    newData.find((row) => row.id === id)[name] = value;
    setOrderingSimulationEvaluationRequests(newData);
  };

  const columns = useOrderingLucilaPreColumns(
    riskEvaluationAcceptRejectColumns(
      updatePreoffer,
      setOpenOtherRejectionReason,
      setSelectedPreOfferEvaluation,
    ),
  ).filter((column) => ORDERING_COLUMNS.includes(column.field));

  const disabled = orderingSimulationEvaluationRequests.some(
    (document) => document.rejectionReason === '',
  );

  return (
    <RejectEvaluationRequestsForm
      rejectEvaluationRequests={rejectOrderingSimulation}
      analystDescription={analystDescription}
      setAnalystDescription={setAnalystDescription}
      columns={columns}
      disabled={disabled}
      sendEmail={sendEmail}
      setSendEmail={setSendEmail}
      loading={loading}
      error={error}
      rows={orderingSimulationEvaluationRequests}
      openOtherRejectionReason={openOtherRejectionReason}
      setOpenOtherRejectionReason={setOpenOtherRejectionReason}
      updatePreOfferEvaluationRequests={updatePreoffer}
      selectedPreOfferEvaluation={selectedPreOfferEvaluation}
    />
  );
};

RejectOrderingSimulationEvaluationRequestsDialog.propTypes = {
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      company: PropTypes.shape({
        id: PropTypes.string.isRequired,
      }),
    }),
  ).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default RejectOrderingSimulationEvaluationRequestsDialog;
