import React from 'react';
import { useMutation } from '@apollo/client';
import PropTypes from 'prop-types';
import LoadingButton from '@mui/lab/LoadingButton';
import { useBooleanState, useSnackBars } from '@fingo/lib/hooks';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Button from '@mui/material/Button';
import {
  SEND_PREOFFER_EVALUATION_REQUEST_TO_PENDING_DOCUMENTS,
  GET_COMPANY_EVALUATIONS,
} from '@fingo/lib/graphql';
import { GET_PREOFFERS_REQUESTS } from '../../../graphql/default/GetPreoffersRequestsQuery';

const SendToPendingDocuments = ({ disabled, documentsToEvaluate }) => {
  const [openDialog, setOpenDialog] = useBooleanState();
  const { addAlert } = useSnackBars();
  const folios = documentsToEvaluate.map((({ folio }) => folio)).join(', ');
  const [sendInvoicesToPendingDocuments, { loading }] = useMutation(
    SEND_PREOFFER_EVALUATION_REQUEST_TO_PENDING_DOCUMENTS,
    {
      variables: {
        evaluationIds: documentsToEvaluate.map((inv) => inv.preoffer.preofferevaluationrequest.id),
      },
      refetchQueries: [
        GET_COMPANY_EVALUATIONS,
        GET_PREOFFERS_REQUESTS,
      ],
      onCompleted: () => {
        setOpenDialog(false);
        addAlert({
          id: 'pending-documents-snackbar',
          message: 'Facturas enviadas a Pendiente de Documentos',
          severity: 'success',
          color: 'success',
        });
      },
    },
  );
  return (
    <>
      <Button
        color="warning"
        size="small"
        variant="contained"
        disabled={disabled}
        onClick={setOpenDialog}
      >
        Revisión Docs.
      </Button>
      <FingoDialog
        open={openDialog}
        handleClose={setOpenDialog}
        title="Facturas a Revisión de Documentos"
        subtitle={`Enviar folios ${folios} a Revisión de Documentos`}
        dialogActionButton={(
          <LoadingButton
            color="primary"
            variant="contained"
            size="small"
            loading={loading}
            onClick={sendInvoicesToPendingDocuments}
          >
            Aceptar
          </LoadingButton>
        )}
      />
    </>
  );
};

SendToPendingDocuments.propTypes = {
  disabled: PropTypes.bool.isRequired,
  documentsToEvaluate: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      folio: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default SendToPendingDocuments;
