import { CollapsibleList } from '@fingo/lib/components/lists/CollapsibleList';
import { InvoiceType } from '@fingo/lib/propTypes';
import React from 'react';
import usePredictiveModelsResults from '../../../../../hooks/usePredictiveModelsResults';

const PredictiveModelsList = ({ invoice }) => {
  const { predictiveModelsConfig: items } = usePredictiveModelsResults(invoice);

  return <CollapsibleList title="Modelos predictivos" items={items} startOpen />;
};

PredictiveModelsList.propTypes = {
  invoice: InvoiceType.isRequired,
};

export default PredictiveModelsList;
